import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import { asciiChars, spaceCheck } from '@@/common/utils/patterns';
import { isSet } from '@@/common/utils/helpers';
import { SIcon, SInput } from '@strana-artw/ui-kit';
export default {
  name: 'FormFieldPassword',
  components: {
    SInput: SInput,
    SIcon: SIcon
  },
  inject: ['validation'],
  props: {
    field: {
      type: String,
      default: 'password'
    },
    label: {
      type: String,
      default: 'Пароль'
    },
    showPass: {
      type: Boolean,
      default: false
    },
    sameAs: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errors: function errors() {
      // form.password_1
      if (!this.validation.$v[this.field].$dirty) {
        return '';
      }
      var _this$validation$$v$t = this.validation.$v[this.field],
        asciiChars = _this$validation$$v$t.asciiChars,
        required = _this$validation$$v$t.required,
        minLength = _this$validation$$v$t.minLength,
        maxLength = _this$validation$$v$t.maxLength,
        serverError = _this$validation$$v$t.serverError,
        sameAsPassword = _this$validation$$v$t.sameAsPassword,
        spaceCheck = _this$validation$$v$t.spaceCheck;
      if (!asciiChars) {
        return 'Пароль должен содержать только латинские буквы, цифры и символы';
      }
      if (!spaceCheck) {
        return 'Пароль не должен содержать пробелы';
      }
      if (!required) {
        return 'Введите пароль';
      }
      if (!minLength) {
        return 'Слишком короткий пароль';
      }
      if (!maxLength) {
        return 'Слишком длинный пароль';
      }
      if (isSet(sameAsPassword) && !sameAsPassword) {
        return 'Пароли должны совпадать';
      }
      if (!serverError) {
        return this.validation.serverErrors[this.field] || 'Ошибка на сервере';
      }
      return '';
    },
    appendIcon: function appendIcon() {
      if (this.disabled) {
        return undefined;
      }
      if (this.editing) {
        return 'edit';
      }
      return this.showPass ? 'eye-off' : 'eye';
    }
  },
  created: function created() {
    var field = this.field;
    this.$set(this.validation.validators.form, field, _objectSpread({
      asciiChars: asciiChars,
      required: required,
      minLength: minLength(8),
      maxLength: maxLength(35),
      spaceCheck: spaceCheck,
      serverError: function serverError() {
        return !this.serverErrors[field];
      }
    }, Boolean(this.sameAs) && {
      sameAsPassword: sameAs(this.sameAs)
    }));
    this.$set(this.validation.form, field, '');
  },
  beforeDestroy: function beforeDestroy() {
    this.$delete(this.validation.form, this.field);
    this.$delete(this.validation.validators.form, this.field);
  },
  methods: {
    clearServerError: function clearServerError() {
      if (this.validation.serverErrors[this.field]) {
        this.$delete(this.validation.serverErrors, this.field);
      }
    },
    onEnter: function onEnter() {
      this.$emit('enterClick');
    }
  }
};
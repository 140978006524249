var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('SInput', {
    class: _vm.$style.inputWrapper,
    attrs: {
      "type": _vm.showPass ? 'text' : 'password',
      "placeholder": _vm.placeholder,
      "label": _vm.label,
      "show-error": _vm.errors,
      "error-message": _vm.errors,
      "autocomplete": _vm.autocomplete,
      "disabled": _vm.disabled
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onEnter.apply(null, arguments);
      },
      "blur": function blur($event) {
        return _vm.validation.$v[_vm.field].$touch();
      },
      "input": _vm.clearServerError,
      "click": function click(e) {
        return _vm.$emit('click', e);
      }
    },
    scopedSlots: _vm._u([!_vm.disabled ? {
      key: "button",
      fn: function fn() {
        return [_c('SIcon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": _vm.showPass ? 'i-eye-off' : 'i-eye',
            "size": "24"
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.$emit('clickAppend');
            }
          }
        })];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.validation.$v[_vm.field].$model,
      callback: function callback($$v) {
        _vm.$set(_vm.validation.$v[_vm.field], "$model", $$v);
      },
      expression: "validation.$v[field].$model"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.LoginPage
  }, [_c('h1', {
    staticClass: "h4",
    class: _vm.$style.heading
  }, [_vm._v("\n    Вход в кабинет\n  ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.contentWrapper
  }, [_c('VFadeTransition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_c('VForm', {
    key: "login",
    attrs: {
      "on-key-press": {
        Enter: _vm.handleSubmit
      }
    }
  }, [_c('div', {
    class: _vm.$style.input
  }, [_c('FormFieldEmail', {
    attrs: {
      "autocomplete": "email"
    }
  })], 1), _vm._v(" "), _c('div', {
    class: _vm.$style.input
  }, [_c('FormFieldPassword', {
    class: _vm.$style.input,
    attrs: {
      "show-pass": _vm.showPass,
      "autocomplete": "current-password"
    },
    on: {
      "clickAppend": function clickAppend($event) {
        _vm.showPass = !_vm.showPass;
      }
    }
  })], 1)])], 1), _vm._v(" "), _c('NuxtLink', {
    key: "forgotPassword",
    class: _vm.$style.forgotPasswordMobile,
    attrs: {
      "to": "/account/agents/forgot-password"
    }
  }, [_vm._v("\n      Забыли пароль?\n    ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.bottomWrapper
  }, [_c('SButton', {
    class: _vm.$style.loginBtn,
    attrs: {
      "theme": "primary",
      "rounded": "",
      "size": "x-large",
      "disabled": _vm.isSubmitDisabled,
      "loading": _vm.isLoading,
      "type": "submit"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("\n        Войти\n      ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.additionalButtonsWrapper
  }, [_c('NuxtLink', {
    class: [_vm.$style.additionalButton, _vm.$style.forgot],
    attrs: {
      "to": "/account/forgot-password"
    }
  }, [_vm._v("\n          Забыли пароль?\n        ")]), _vm._v(" "), _c('NuxtLink', {
    class: [_vm.$style.additionalButton, _vm.$style.register],
    attrs: {
      "to": "/account/agents/registration"
    }
  }, [_vm._v("\n          Зарегистрироваться\n        ")])], 1)], 1)], 1), _vm._v(" "), _c('div', {
    class: _vm.$style.serviceWrapper
  }, [_c('TechSupport')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
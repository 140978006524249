var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.wrapper
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editing,
      expression: "editing"
    }],
    class: _vm.$style.blockSolution
  }, [_c('SIcon', {
    class: _vm.$style.icon,
    attrs: {
      "icon": "i-checkSolition",
      "size": "24"
    },
    on: {
      "click": _vm.confirm
    }
  }), _vm._v(" "), _c('SIcon', {
    class: _vm.$style.icon,
    attrs: {
      "icon": "i-closeSolition",
      "size": "24"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  })], 1), _vm._v(" "), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_mCEFP{position:relative}.wrapper_mCEFP .blockSolution_nd8jI{display:-webkit-box;display:-ms-flexbox;display:flex;gap:18px;position:absolute;right:0;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_mCEFP",
	"blockSolution": "blockSolution_nd8jI"
};
module.exports = ___CSS_LOADER_EXPORT___;

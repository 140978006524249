var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.hasSupportFlag ? _c(_setup.STooltip, _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c(_setup.SButton, {
          class: _vm.$style.service,
          attrs: {
            "equal": "",
            "theme": "business-black"
          },
          nativeOn: {
            "click": function click($event) {
              return _setup.handleSupport.apply(null, arguments);
            }
          }
        }, [_c(_setup.SIcon, {
          class: _vm.$style.serviceIcon,
          attrs: {
            "icon": "i-service",
            "size": "24"
          }
        })], 1)];
      },
      proxy: true
    }, !_setup.isMobileOrTablet ? {
      key: "content",
      fn: function fn() {
        return [_c('span', {
          class: _vm.$style.tooltip
        }, [_vm._v("\n      Связаться с поддержкой\n    ")])];
      },
      proxy: true
    } : null], null, true)
  }, 'STooltip', _setup.tooltipProps, false)) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('InputConfirm', {
    class: [_vm.$style.inputWrapper, _defineProperty({}, _vm.$style.required, _vm.required)],
    attrs: {
      "editing": _vm.isEditing
    },
    on: {
      "confirm": function confirm($event) {
        return _vm.$emit('confirm');
      },
      "cancel": function cancel($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_c('SInput', {
    class: _defineProperty({}, _vm.$style.error, !!_vm.errorMessage),
    attrs: {
      "error-message": _vm.errorMessage,
      "show-error": _vm.errorMessage,
      "type": "email",
      "name": "email",
      "label": "E-mail",
      "autocomplete": _vm.autocomplete,
      "disabled": _vm.disabled
    },
    on: {
      "blur": function blur($event) {
        return _vm.validation.$v[_vm.field].$touch();
      },
      "click": function click($event) {
        return _vm.$emit('click');
      },
      "input": _vm.clearServerError
    },
    model: {
      value: _vm.validation.$v[_vm.field].$model,
      callback: function callback($$v) {
        _vm.$set(_vm.validation.$v[_vm.field], "$model", $$v);
      },
      expression: "validation.$v[field].$model"
    }
  }), _vm._v(" "), !_vm.required ? _c('span', {
    class: _vm.$style.hint
  }, [_vm._v("\n    " + _vm._s(_vm.$t('notRequired')) + "\n  ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
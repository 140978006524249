import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
// Mixins
import mixins from '../../../utils/mixins';
import BindsAttrs from '../../../mixins/binds-attrs';
import { provide as RegistrableProvide } from '../../../mixins/registrable';

/* eslint-disable no-prototype-builtins,new-cap,no-empty-function */
export default mixins(BindsAttrs, RegistrableProvide('form')).extend({
  name: 'v-form',
  provide: function provide() {
    return {
      form: this
    };
  },
  inheritAttrs: false,
  props: {
    disabled: Boolean,
    lazyValidation: Boolean,
    readonly: Boolean,
    value: Boolean,
    autocomplete: {
      type: [Boolean, String],
      default: null
    },
    onKeyPress: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      inputs: [],
      watchers: [],
      errorBag: {}
    };
  },
  watch: {
    errorBag: {
      handler: function handler(val) {
        var errors = Object.values(val).includes(true);
        this.$emit('input', !errors);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    watchInput: function watchInput(input) {
      var _this = this;
      var watcher = function watcher(input) {
        return input.$watch('hasError', function (val) {
          _this.$set(_this.errorBag, input._uid, val);
        }, {
          immediate: true
        });
      };
      var watchers = {
        _uid: input._uid,
        valid: function valid() {},
        shouldValidate: function shouldValidate() {}
      };
      if (this.lazyValidation) {
        // Only start watching inputs if we need to
        watchers.shouldValidate = input.$watch('shouldValidate', function (val) {
          if (!val) {
            return;
          }

          // Only watch if we're not already doing it
          if (_this.errorBag.hasOwnProperty(input._uid)) {
            return;
          }
          watchers.valid = watcher(input);
        });
      } else {
        watchers.valid = watcher(input);
      }
      return watchers;
    },
    validate: function validate() {
      return this.inputs.filter(function (input) {
        return !input.validate(true);
      }).length === 0;
    },
    reset: function reset() {
      this.inputs.forEach(function (input) {
        return input.reset();
      });
      this.resetErrorBag();
    },
    resetErrorBag: function resetErrorBag() {
      var _this2 = this;
      if (this.lazyValidation) {
        // Account for timeout in validatable
        setTimeout(function () {
          _this2.errorBag = {};
        }, 0);
      }
    },
    resetValidation: function resetValidation() {
      this.inputs.forEach(function (input) {
        return input.resetValidation();
      });
      this.resetErrorBag();
    },
    register: function register(input) {
      this.inputs.push(input);
      this.watchers.push(this.watchInput(input));
    },
    unregister: function unregister(input) {
      var found = this.inputs.find(function (i) {
        return i._uid === input._uid;
      });
      if (!found) {
        return;
      }
      var unwatch = this.watchers.find(function (i) {
        return i._uid === found._uid;
      });
      if (unwatch) {
        unwatch.valid();
        unwatch.shouldValidate();
      }
      this.watchers = this.watchers.filter(function (i) {
        return i._uid !== found._uid;
      });
      this.inputs = this.inputs.filter(function (i) {
        return i._uid !== found._uid;
      });
      this.$delete(this.errorBag, found._uid);
    }
  },
  render: function render(h) {
    var _this3 = this;
    return h('form', {
      staticClass: 'v-form',
      attrs: _objectSpread({
        autocomplete: this.autocomplete,
        novalidate: true
      }, this.attrs$),
      on: {
        submit: function submit(e) {
          e.preventDefault();
          _this3.$emit('submit', e);
        },
        keyup: function keyup(e) {
          e.stopPropagation();
          e.preventDefault();
          if (typeof _this3.onKeyPress[e.code] === 'function') {
            _this3.onKeyPress[e.code]();
          }
        }
      }
    }, this.$slots.default);
  }
});
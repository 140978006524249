// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hint_mnogL{font-size:1.2rem;line-height:1.4rem;word-break:break-word;word-wrap:break-word;color:#677b8f;-ms-hyphens:auto;hyphens:auto;-webkit-transition:opacity .4s ease-in-out;transition:opacity .4s ease-in-out}.inputWrapper_d9N1R{margin-bottom:3rem}.error_0vLpo~.hint_mnogL{visibility:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hint": "hint_mnogL",
	"inputWrapper": "inputWrapper_d9N1R",
	"error": "error_0vLpo"
};
module.exports = ___CSS_LOADER_EXPORT___;
